import { ActionBar } from '@/modules/application';
import { Card, CardContent, Typography, TextField, Button, Stack, Checkbox, FormControlLabel, Tooltip } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { LocationFormModel } from './LocationFormModel';
import { LoadingButton } from '@mui/lab';
import { LocationGeofenceEditor } from '../LocationGeofenceEditor';
import { CountrySelect } from '../CountrySelect';
import { mapboxService } from '@/modules/map/lib/mapbox.service';
import { GeocodedAddressSelectDialog } from '../GeocodedAddressSelectDialog';
import { IGeocoderFeature } from '@/modules/map/lib/mapbox.contracts';
import { createHexagon } from '@/modules/map/lib/geo.utils';
import { HexagonOutlined, InfoOutlined } from '@mui/icons-material';
import { ActionBarDeleteButton } from '@/modules/application/components/ActionBarDeleteButton';
import { locationService } from '../../api/locations/location.service';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { ApiResponseCode } from '@/lib/api/api-response-code';
import { LocationSelect } from '../LocationSelect';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';
import { useUser } from '@/modules/users/contexts/UserContext';

interface LocationFormDisplayProps {
  form: UseFormReturn<LocationFormModel>;
  onSubmit: (data: LocationFormModel) => void;
  isCreate?: boolean;
  locationId?: number;
}

// const geocodingClient = Geocoder(mapbox({ accessToken: MapboxToken }));

const LocationFormDisplay: FunctionComponent<LocationFormDisplayProps> = ({ form, onSubmit, isCreate = false, locationId }) => {
  const { formState, register, handleSubmit, getValues, control, watch, setValue } = form;
  const { errors, isSubmitting } = formState;
  const [geofence, setGeofence] = useState<number[][]>([]);
  const [isSelectGeocodedAddressDialogOpen, setIsSelectGeocodedAddressDialogOpen] = useState(false);
  const [geocodedAddresses, setGeocodedAddresses] = useState<IGeocoderFeature[]>();
  const navigate = useNavigate();
  const { hasPermission } = useUser();

  async function createGeofenceFromAddress() {
    const currentFormValues = getValues();
    const geocodeResult = await mapboxService.forwardGeocode({
      city: currentFormValues.city,
      country: currentFormValues.country,
      postalCode: currentFormValues.postalCode,
      streetName: currentFormValues.streetName,
      streetNumber: currentFormValues.streetNumber,
    });

    if (geocodeResult.isSuccess) {
      setGeocodedAddresses(geocodeResult.payload.features);
      setIsSelectGeocodedAddressDialogOpen(true);
    }
  }

  function onGeocodedAddressSelectDialogClosed(selectedAddress?: IGeocoderFeature, radiusInMeters?: number) {
    // Add geofence from gecodre result
    if (selectedAddress) {
      console.log({ selectedAddress });
      const hexagonalPolygon = createHexagon(selectedAddress.center[1], selectedAddress.center[0], radiusInMeters ?? 500);
      setValue('geofence', hexagonalPolygon);
    }
    setIsSelectGeocodedAddressDialogOpen(false);
  }

  async function deleteLocation() {
    console.log({ locationId });

    if (locationId) {
      const response = await locationService.delete(locationId);
      if (!response.isSuccess) {
        if (response.responseCode === ApiResponseCode.LOCATION_HAS_ASSETS) {
          toast.error('Location has Assets assigned. Move them to a different Location before deleting this Location.', {
            autoClose: 10000,
          });
        } else if (response.responseCode === ApiResponseCode.LOCATION_IS_SYSTEM) {
          toast.error('This is a System Location, and System Locations cannot be deleted.');
        } else if (response.responseCode === ApiResponseCode.LOCATION_HAS_CHILDREN) {
          toast('Location has children. Delete or re-assign them to a different Parent Location, before deleting this Location.');
        } else {
          toast.error('Error Deleting Location');
        }
      } else {
        toast.success('Location deleted');
        navigate('/app/locations/overview');
      }
    }
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid gap-4 lg:grid-cols-2 2xl:grid-cols-3 ">
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h6" className="pb-2" color="text.secondary">
              Details
            </Typography>
            <div className="flex w-full flex-col gap-y-2">
              <Controller
                name="parentLocationId"
                control={control}
                render={({ field: { onChange, onBlur, value, ref }, formState, fieldState }) => (
                  <LocationSelect
                    onlyEligibleParentsForLocationId={locationId}
                    exludedLocationIds={isCreate || !locationId ? [] : [locationId]}
                    helperText={errors.name?.message}
                    label="Parent Location"
                    selectedLocationId={value !== null ? value : undefined}
                    onSelected={(selectedLocation) => {
                      if (selectedLocation) {
                        onChange(selectedLocation.dto.id);
                      } else {
                        onChange(null);
                      }
                    }}
                  />
                )}
              />

              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField error={errors.name !== undefined} helperText={errors.name?.message} label="Name" {...field} />
                )}
              />

              <Controller
                name="code"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    error={errors.code !== undefined}
                    helperText={errors.code?.message}
                    label="Code"
                    {...field}
                    disabled={form.getValues('isSystem')}
                  />
                )}
              />

              <Controller
                name="description"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    error={errors.description !== undefined}
                    helperText={errors.description?.message}
                    label="Description"
                    {...field}
                  />
                )}
              />

              <Controller
                name="includeInReturnRate"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} />}
                    label={
                      <span style={{ display: 'flex', alignItems: 'center' }}>
                        Include in return rate calculation
                        <Tooltip
                          title="Decides whether assets arriving at this location will be included in the return rate of the location where the asset departed from"
                          placement="bottom"
                          arrow
                        >
                          <InfoOutlined fontSize="small" style={{ marginLeft: 4 }} />
                        </Tooltip>
                      </span>
                    }
                  />
                )}
              />

              {/* <TextField id="outlined-basic" label="Password" variant="outlined" /> */}
            </div>
          </CardContent>
        </Card>

        <Card variant="outlined">
          <CardContent>
            <Typography variant="h6" className="pb-2" color="text.secondary">
              Address
            </Typography>

            <div className={'flex flex-col gap-y-2'}>
              <Controller
                name="country"
                control={control}
                render={({ field: { onChange, onBlur, value, ref }, formState, fieldState }) => (
                  <>
                    <CountrySelect onChange={onChange} value={value} />
                  </>
                )}
              />
              <Controller
                name="city"
                control={control}
                render={({ field }) => (
                  <TextField error={errors.city !== undefined} helperText={errors.city?.message} label="City" {...field} />
                )}
              />
              <Controller
                name="streetName"
                control={control}
                render={({ field }) => (
                  <TextField error={errors.streetName !== undefined} helperText={errors.streetName?.message} label="Street Name" {...field} />
                )}
              />
              <Controller
                name="streetNumber"
                control={control}
                render={({ field }) => (
                  <TextField
                    error={errors.streetNumber !== undefined}
                    helperText={errors.streetNumber?.message}
                    label="Street Number"
                    {...field}
                  />
                )}
              />
              <Controller
                name="postalCode"
                control={control}
                render={({ field }) => (
                  <TextField error={errors.postalCode !== undefined} helperText={errors.postalCode?.message} label="Postal Code" {...field} />
                )}
              />
              <Stack spacing={2} direction="row">
                <Button onClick={createGeofenceFromAddress} variant="text" startIcon={<HexagonOutlined className="rotate-90" />}>
                  Create Geofence from Address..
                </Button>
              </Stack>
              {geocodedAddresses && (
                <GeocodedAddressSelectDialog
                  onClose={onGeocodedAddressSelectDialogClosed}
                  open={isSelectGeocodedAddressDialogOpen}
                  options={geocodedAddresses}
                />
              )}
            </div>
          </CardContent>
        </Card>

        <Card variant="outlined">
          <CardContent>
            <Typography variant="h6" className="pb-2" color="text.secondary">
              Geofence
            </Typography>

            <div className={'flex flex-col'}>
              <Controller
                name="geofence"
                control={control}
                render={({ field: { onChange, onBlur, value, ref }, formState, fieldState }) => (
                  <>
                    <LocationGeofenceEditor onPolygonDrawn={onChange} polygon={value ?? []} />
                  </>
                )}
              />
            </div>
          </CardContent>
        </Card>
      </div>
      {hasPermission(Permission.LOCATIONS_EDIT) && (
        <ActionBar>
          <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
            Save
          </LoadingButton>
          <ActionBarDeleteButton
            deleteConfirmationQuestion="Are you sure you would like to delete this Location? The Events linked to this Location will not be deleted, to retain a complete history of your Assets."
            onDeleteConfirmed={deleteLocation}
            isVisible={!isCreate && !form.getValues('isSystem')}
            isLoading={isSubmitting}
          />
        </ActionBar>
      )}
    </form>
  );
};

export default LocationFormDisplay;
