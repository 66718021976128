import { FunctionComponent, useMemo, useCallback, useEffect } from 'react';
import { GridApi, GridOptions, CellClickedEvent, SortChangedEvent, GridReadyEvent, ICellRendererParams, ColDef } from 'ag-grid-community';
import { useNavigate } from 'react-router-dom';

import { PoGrid, BASE_GRID_OPTIONS } from '@/components/grid/PoGrid';
import { AssetModel } from '../types/AssetModel';
import { useGridColumnState } from '@/hooks/useGridColumnState';
import { UserSettingKey } from '@/modules/users/api/user-settings/user-setting.contracts';
import { ColumnID } from '@/components/grid/column-ids';
import { RelativeDateCellRenderer } from '@/components/grid/cells/RelativeDateCellRenderer';
import LinkCellRenderer, { LinkCellRendererParams } from '@/components/grid/cells/LinkCellRenderer';
import { TrackerType } from '@/modules/trackers';
import { TrackerUniqueIdentifierChip } from '@/modules/trackers/components/TrackerUniqueIdentifierChip';
import { PeriodInDaysCellRenderer } from '@/components/grid/cells/PeriodInDaysCellRenderer';

interface AssetsGridProps {
  data: AssetModel[] | undefined;
  isLoading: boolean;
  onSelectionChanged: (selectedRows: AssetModel[]) => void;
  onRowDoubleClicked: (assetId: string) => void;
  onExportToExcelClicked: (gridApi: GridApi<AssetModel> | undefined) => void;
  onSortChanged: (event: SortChangedEvent<AssetModel>) => void;
  onGridReady: (event: GridReadyEvent<AssetModel>) => void;
}

export const AssetsGrid: FunctionComponent<AssetsGridProps> = ({
  data,
  isLoading,
  onSelectionChanged,
  onRowDoubleClicked,
  onExportToExcelClicked,
  onSortChanged,
  onGridReady,
}) => {
  const navigate = useNavigate();

  // Initialize the grid column state hook
  const {
    setColumnStateGridApi,
    handleColumnStateChange,
    columnState,
    applyStateToDefinitions,
    setIsAutoSaveEnabled,
    setDefaultColumnState,
  } = useGridColumnState(UserSettingKey.ASSET_OVERVIEW_GRID_COLUMN_STATE);

  // Enable auto-saving of column state
  useEffect(() => {
    setIsAutoSaveEnabled(true);
  }, []);

  const columns: ColDef<AssetModel>[] = useMemo(() => {
    const baseColumns: ColDef<AssetModel>[] = [
      {
        colId: ColumnID.SELECTION_CHECKBOX,
        checkboxSelection: true,
        headerCheckboxSelection: false,
        resizable: false,
        width: 40,
        minWidth: 40,
        maxWidth: 40,
        suppressColumnsToolPanel: true,
        suppressMenu: true,
        lockVisible: true,
        sortable: false,
        pinned: 'left',
      },
      {
        colId: ColumnID.ASSET_CODE,
        field: 'code',
      },
      {
        colId: ColumnID.ASSET_TYPE_NAME,
        headerName: 'Type',
        valueGetter: (params) => params.data?.assetType.name,
      },
      {
        colId: ColumnID.LOCATION_NAME,
        field: 'location.name',
        headerName: 'Location',
        cellRenderer: LinkCellRenderer,
        cellRendererParams: (params: ICellRendererParams<AssetModel>): LinkCellRendererParams => ({
          pathname: `/app/locations/${params.data?.location?.id}`,
        }),
      },
      {
        colId: ColumnID.FIRST_EVENT_DATE,
        field: 'firstEventDate',
        headerName: 'First Event Date',
        cellRenderer: RelativeDateCellRenderer,
        valueFormatter: (params) => (params.value ? new Date(params.value).toISOString() : ''),
      },
      {
        colId: ColumnID.LAST_EVENT_DATE,
        field: 'lastEventDate',
        cellRenderer: RelativeDateCellRenderer,
        valueFormatter: (params) => (params.value ? new Date(params.value).toISOString() : ''),
      },
      {
        colId: ColumnID.STAYTIME,
        field: 'locationEnteredDate',
        headerName: 'Stay Time',
        cellRenderer: PeriodInDaysCellRenderer,
        valueFormatter: (params) => (params.value ? new Date(params.value).toISOString() : ''),
      },
    ];

    // Apply saved column state to the column definitions
    if (data) {
      applyStateToDefinitions(baseColumns);
    }

    // Add tracker columns dynamically
    if (data) {
      const maxTrackers = data.reduce((max, asset) => Math.max(max, asset.trackers.length), 0);

      for (let i = 0; i < maxTrackers; i++) {
        baseColumns.push({
          colId: `tracker_${i + 1}`,
          headerName: `Tracker ${i + 1}`,
          cellClass: 'flex items-center ',
          flex: i === maxTrackers - 1 ? 1 : 0,
          sortable: false,
          minWidth: 250,
          width: 250,
          suppressMovable: true,
          cellRenderer: (params: ICellRendererParams<AssetModel>) => {
            const tracker = params.data?.trackers[i];
            if (!tracker) return '';

            return (
              <div className="mt-0.5 flex h-8 py-1">
                <TrackerUniqueIdentifierChip
                  trackerType={tracker.type}
                  uniqueIdentifier={
                    tracker.type === TrackerType.Barcode
                      ? tracker.barcode
                      : tracker.type === TrackerType.RFID
                        ? tracker.epc
                        : tracker.deviceId
                  }
                />
              </div>
            );
          },
        });
      }
    }

    return baseColumns;
  }, [data, columnState]);

  const onCellClicked = useCallback((params: CellClickedEvent<AssetModel>) => {
    if (params.column.getColId() === ColumnID.SELECTION_CHECKBOX) {
      const node = params.node;
      node.setSelected(!node.isSelected());
    }
  }, []);

  const customGridOptions: GridOptions<AssetModel> = useMemo(
    (): GridOptions<AssetModel> => ({
      ...BASE_GRID_OPTIONS,
      onRowDoubleClicked(event) {
        const id = event.data?.id;
        if (id) {
          onRowDoubleClicked(id.toString());
        }
      },
      onGridReady: (event) => {
        onGridReady(event);
        setColumnStateGridApi(event.api);
      },
      onSortChanged: (event) => {
        if (event.columns && event.columns[0] && event.source === 'uiColumnSorted') {
          event.api.applyColumnState({
            state: [
              {
                colId: event.columns[0].getColId(),
                sort: event.columns[0].getSort(),
              },
            ],
            defaultState: { sort: null },
          });
        }
        handleColumnStateChange(event);
        onSortChanged(event);
      },
      getContextMenuItems: (params) => [
        'copy',
        'separator',
        {
          name: 'Export to Excel',
          action: () => onExportToExcelClicked(params.api),
        },
      ],
      getRowId: (params) => params.data.id.toString(),
      suppressRowClickSelection: true,
      rowSelection: 'multiple',
      onCellClicked,
      onSelectionChanged: (event) => {
        const selectedRows = event.api.getSelectedRows();
        onSelectionChanged(selectedRows);
      },
      // Grid column state change handlers
      onColumnMoved: handleColumnStateChange,
      onColumnVisible: handleColumnStateChange,
      onColumnResized: handleColumnStateChange,
      suppressMultiSort: true,
    }),
    [],
  );

  return (
    <PoGrid
      isLoading={isLoading}
      colDefs={columns}
      gridOptions={customGridOptions}
      disableResizeColumnsToFit
      rowData={data}
      disableDefaultGridOptions
    />
  );
};
