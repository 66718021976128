import { ApiResponse } from '@/lib/api/api-response';
import { apiClient } from '@/lib/api/base-client';
import { AssetModel } from '../../types/AssetModel';
import {
  AssetCreateDTO,
  AssetFilterOptionsDTO,
  AssetReadDTO,
  AssetTrackerUpdateDTO,
  AssetUpdateDTO,
  EventMapBreadcrumbDTO,
  MoveAssetsDTO,
} from './asset.contracts';
import { Dayjs } from 'dayjs';
import { PagePaginationQueryDtoParams, PagePaginationResultDto } from '@/lib/api/pagination.page.dto';

export class AssetService {
  public basePath = 'asset';

  async create(assetToCreate: AssetCreateDTO): Promise<ApiResponse<AssetModel>> {
    try {
      const applicationResponse = await apiClient.post<AssetReadDTO>(this.basePath + '/', assetToCreate);
      return applicationResponse.processPayload(AssetModel.fromDTO);
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async update(assetId: number, assetToUpdate: AssetUpdateDTO): Promise<ApiResponse<undefined>> {
    try {
      const applicationResponse = await apiClient.patch<undefined>(`${this.basePath}/${assetId}`, assetToUpdate);
      return applicationResponse;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async get(
    query: AssetFilterOptionsDTO,
    pagination: PagePaginationQueryDtoParams,
  ): Promise<ApiResponse<PagePaginationResultDto<AssetModel>>> {
    try {
      const applicationResponse = await apiClient.get<PagePaginationResultDto<AssetReadDTO>>(this.basePath, { ...query, ...pagination });
      return applicationResponse.processPagedPayload((payload) => {
        return payload.map(AssetModel.fromDTO);
      });
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getById(id: number): Promise<ApiResponse<AssetModel>> {
    try {
      const applicationResponse = await apiClient.get<AssetReadDTO>(`${this.basePath}/${id}`);
      return applicationResponse.processPayload(AssetModel.fromDTO);
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getByCode(code: string): Promise<ApiResponse<AssetModel | null>> {
    try {
      const applicationResponse = await apiClient.get<AssetReadDTO>(`${this.basePath}/by-code`, { code });
      return applicationResponse.processPayload(AssetModel.fromDTO);
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async updateTrackers(assetId: number, trackerIds: number[]): Promise<ApiResponse<AssetModel>> {
    try {
      const updatePayload: AssetTrackerUpdateDTO = {
        trackerIds: trackerIds,
      };
      const applicationResponse = await apiClient.put<AssetReadDTO>(`${this.basePath}/${assetId}/trackers`, updatePayload);
      return applicationResponse.processPayload(AssetModel.fromDTO);
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async delete(id: number): Promise<ApiResponse<void>> {
    try {
      const applicationResponse = await apiClient.delete<void>(`${this.basePath}/${id}`);
      return applicationResponse;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getCount(): Promise<ApiResponse<number>> {
    try {
      const applicationResponse = await apiClient.get<{ count: number }>(`${this.basePath}/count`);
      return applicationResponse.processPayload((payload) => {
        return payload.count;
      });
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getPosition(id: number): Promise<ApiResponse<[number, number] | undefined>> {
    try {
      const applicationResponse = await apiClient.get<[number, number]>(`${this.basePath}/${id}/position`);
      return applicationResponse;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getBreadcrumbs(args: { assetId: number; dateFrom?: Dayjs; dateTo?: Dayjs }): Promise<ApiResponse<EventMapBreadcrumbDTO[]>> {
    try {
      const applicationResponse = await apiClient.get<EventMapBreadcrumbDTO[]>(`${this.basePath}/${args.assetId}/breadcrumbs`, {
        dateFrom: args.dateFrom?.toISOString(),
        dateTo: args.dateTo?.toISOString(),
      });
      return applicationResponse;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async moveAssetsToLocation(payload: MoveAssetsDTO): Promise<ApiResponse<boolean>> {
    console.log('Move assets to location', payload);

    try {
      const applicationResponse = await apiClient.post(this.basePath + '/move', payload);
      return applicationResponse.processPayload(() => true);
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async unarchive(id: number): Promise<ApiResponse<void>> {
    try {
      const applicationResponse = await apiClient.put<void>(`${this.basePath}/archived/${id}`, {});
      return applicationResponse;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }
}

export const assetService = new AssetService();
