import { PoGrid } from '@/components/grid/PoGrid';
import { FunctionComponent, useState, useEffect, useMemo } from 'react';
import { EventModel, EventModelFieldNames } from '../types/EventModel';
import { useApiCall } from '@/hooks/useApiCall';
import { eventService } from '../api/events/event.service';
import ErrorLoadingDataAlert from '@/components/feedback/ErrorLoadingDataAlert';
import {
  ColDef,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  RowDoubleClickedEvent,
  SortChangedEvent,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import LinkCellRenderer, { LinkCellRendererParams } from '../../../components/grid/cells/LinkCellRenderer';
import { RelativeDateCellRenderer } from '@/components/grid/cells/RelativeDateCellRenderer';
import { FilterFieldName, FilterValues } from '@/components/filterbar/FilterBarContext';
import FilterBar from '@/components/filterbar/FilterBar';
import FilterBarSearchButton from '@/components/filterbar/FilterBarSearchButton';
import LocationFilter from '@/components/filterbar/filters/LocationFilter';
import AssetFilter from '@/components/filterbar/filters/AssetFilter';
import DateFilter from '@/components/filterbar/filters/DateFilter';
import { TrackerTypeCellRenderer } from '@/components/grid/cells/TrackerTypeCellRenderer';
import { TabbedPageLayout } from '@/modules/application/layouts/TabbedPageLayout';
import { TabbedPageLayoutBody } from '@/modules/application/components/TabbedPageLayoutBody';
import { toast } from 'react-toastify';
import EventInfoDrawer from '../components/info-drawer/EventInfoDrawer';
import { GpsAccuracyLevelCellRenderer, formatGpsAccuracy } from '@/components/grid/cells/GpsAccuracyCellRenderer';
import ListSelectFilter from '@/components/filterbar/filters/ListSelectFilter';
import { EventSortOption, EventType } from '../api/events/event.contracts';
import { trackerTypeToString } from '@/modules/trackers/lib/tracker.utils';
import { useTranslation } from '@/lib';
import useQueryParamsFilters from '@/hooks/useQueryParamFilters';
import { CacheKey } from '@/providers/cache-provider/cache-key.enum';
import { PagePaginationResultDto, PageSortOrder } from '@/lib/api/pagination.page.dto';
import PaginationControls from '@/components/grid/PaginationControls';
import PagedResultDataText from '@/components/filterbar/PagedResultDataText';
import { ColumnID } from '@/components/grid/column-ids';
import { ARCHIVED_LOCATION_NAME } from '@/modules/locations/api/locations/location.contracts';

// Import the useGridColumnState hook and UserSettingKey
import { useGridColumnState } from '@/hooks/useGridColumnState';
import { UserSettingKey } from '@/modules/users/api/user-settings/user-setting.contracts';

export const EVENT_FETCH_LIMIT = 1000;

export const EventsPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const [selectedEventId, setSelectedEventId] = useState<number | undefined>(undefined);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const { filters, setFiltersToUrl } = useQueryParamsFilters({}, CacheKey.EVENT_FILTERS);

  const {
    data: events,
    isLoading,
    isError,
    setApiCallArg,
  } = useApiCall<PagePaginationResultDto<EventModel>>(() =>
    eventService.get(
      {
        dateFrom: filters.current.dateFrom?.toISOString(),
        dateTo: filters.current.dateTo?.toISOString(),
        locationId: filters.current.locationId,
        assetId: filters.current.assetId,
        eventType: filters.current.eventType,
      },
      {
        page: filters.current.pageNumber,
        limit: EVENT_FETCH_LIMIT,
        order: filters.current.sortDirection ?? PageSortOrder.DESC,
        sort: filters.current.sortOption ?? EventSortOption.OCCURRED_AT,
      },
    ),
  );

  // Initialize the grid column state hook
  const {
    setColumnStateGridApi,
    handleColumnStateChange,
    columnState,
    applyStateToDefinitions,
    setIsAutoSaveEnabled,
    setDefaultColumnState,
  } = useGridColumnState(UserSettingKey.EVENTS_GRID_COLUMN_STATE);

  // Enable auto-saving of column state
  useEffect(() => {
    setIsAutoSaveEnabled(true);
  }, []);

  const onSortChanged = (event: SortChangedEvent) => {
    if (event.columns && event.columns.length > 0 && event.source === 'uiColumnSorted') {
      console.log('Sort Changed', event.columns);

      const changedColumn = event.columns.reverse()[0];

      const changedColumnId = changedColumn.getColId();
      let sortOption: EventSortOption | undefined = undefined;

      switch (changedColumnId) {
        case ColumnID.ASSET_CODE:
          sortOption = EventSortOption.ASSET_CODE;
          break;
        case ColumnID.LOCATION_NAME:
          sortOption = EventSortOption.LOCATION_NAME;
          break;
        case ColumnID.OCCURRED_AT:
          sortOption = EventSortOption.OCCURRED_AT;
          break;
        case ColumnID.TRACKER_TYPE:
          sortOption = EventSortOption.TRACKER_TYPE;
          break;
        case ColumnID.EVENT_TYPE:
          sortOption = EventSortOption.EVENT_TYPE;
          break;
        case ColumnID.GPS_ACCURACY:
          sortOption = EventSortOption.GPS_ACCURACY;
          break;
        case ColumnID.GPS_SOURCE_TYPE:
          sortOption = EventSortOption.GPS_SOURCE_TYPE;
          break;
        case ColumnID.EVENT_SOURCE:
          sortOption = EventSortOption.EVENT_SOURCE;
          break;
        default:
          break;
      }

      let sortOrder: PageSortOrder | undefined = undefined;

      switch (changedColumn.getSort()) {
        case 'asc':
          sortOrder = PageSortOrder.ASC;
          break;
        case 'desc':
          sortOrder = PageSortOrder.DESC;
          break;
        default:
          sortOption = undefined; // If there is no sort direction, remove the sort option
          break;
      }

      // Apply the sorting so only one column can be sorted at a time
      event.api.applyColumnState({
        state: [
          {
            colId: changedColumnId,
            sort: sortOrder === PageSortOrder.ASC ? 'asc' : 'desc',
          },
        ],
        defaultState: { sort: null },
      });

      handleSearch(
        {
          ...filters.current,
          sortOption,
          sortDirection: sortOrder,
        },
        1,
      );
    }
  };

  const onGridReady = (event: GridReadyEvent) => {
    // Set initial sort state to grid
    if (filters.current.sortOption && filters.current.sortDirection) {
      // Convert sort option to column id
      let columnId: ColumnID | undefined = ColumnID.OCCURRED_AT;
      switch (filters.current.sortOption) {
        case EventSortOption.ASSET_CODE:
          columnId = ColumnID.ASSET_CODE;
          break;
        case EventSortOption.LOCATION_NAME:
          columnId = ColumnID.LOCATION_NAME;
          break;
        case EventSortOption.OCCURRED_AT:
          columnId = ColumnID.OCCURRED_AT;
          break;
        case EventSortOption.TRACKER_TYPE:
          columnId = ColumnID.TRACKER_TYPE;
          break;
        case EventSortOption.EVENT_TYPE:
          columnId = ColumnID.EVENT_TYPE;
          break;
        case EventSortOption.GPS_ACCURACY:
          columnId = ColumnID.GPS_ACCURACY;
          break;
        case EventSortOption.GPS_SOURCE_TYPE:
          columnId = ColumnID.GPS_SOURCE_TYPE;
          break;
        case EventSortOption.EVENT_SOURCE:
          columnId = ColumnID.EVENT_SOURCE;
          break;
        default:
          break;
      }

      event.api.applyColumnState({
        state: [
          {
            colId: columnId,
            sort: filters.current.sortDirection === PageSortOrder.ASC ? 'asc' : 'desc',
          },
        ],
        defaultState: { sort: null },
      });
    }

    // Set the grid API for column state management
    setColumnStateGridApi(event.api);
  };

  const customGridOptions: GridOptions<EventModel> = {
    onRowDoubleClicked(event: RowDoubleClickedEvent<EventModel, unknown>) {
      setSelectedEventId(event.data?.id);
      setIsDrawerOpen(true);
    },
    getRowId: (data) => data.data.id.toString(),
    onSortChanged,
    onGridReady,
    onColumnMoved: handleColumnStateChange,
    onColumnVisible: handleColumnStateChange,
    onColumnResized: handleColumnStateChange,
    onColumnPinned: handleColumnStateChange,
    defaultCsvExportParams: {
      fileName: `Events export.csv`,
    },
    defaultExcelExportParams: {
      fileName: `Events export.xlsx`,
    },
    alwaysMultiSort: false,
  };

  const columns: ColDef<EventModel>[] = useMemo(() => {
    const cols: ColDef<EventModel>[] = [
      {
        colId: ColumnID.ASSET_CODE,
        field: EventModelFieldNames.AssetCode,
        headerName: t('event.asset_code'),
      },
      {
        colId: ColumnID.OCCURRED_AT,
        field: EventModelFieldNames.OccurredAt,
        cellRenderer: RelativeDateCellRenderer,
        headerName: t('event.occurred_at'),
      },
      {
        colId: ColumnID.LOCATION_NAME,
        field: EventModelFieldNames.LocationName,
        headerName: t('event.location'),
        cellRenderer: LinkCellRenderer,
        cellRendererParams: (params: ICellRendererParams<EventModel>): LinkCellRendererParams => ({
          pathname: params.data?.location.name === ARCHIVED_LOCATION_NAME ? undefined : `/app/locations/${params.data?.location.id}`,
        }),
      },
      {
        colId: ColumnID.TRACKER_TYPE,
        field: EventModelFieldNames.TrackerType,
        headerName: t('event.tracker_type'),
        cellRenderer: TrackerTypeCellRenderer,
        valueFormatter: (params: ValueFormatterParams<EventModel>) => {
          return trackerTypeToString(params.data?.tracker?.type, t) ?? '';
        },
      },
      {
        colId: ColumnID.EVENT_TYPE,
        headerName: t('event.type.title'),
        cellClass: 'first-letter:uppercase',
        valueGetter: (params: ValueGetterParams<EventModel>) => {
          return t(`event.type.${params.data?.type}`);
        },
      },
      {
        colId: ColumnID.EVENT_SOURCE,
        headerName: t('event.source'),
        valueGetter: (params: ValueGetterParams<EventModel>) => {
          return t(`event.event_source.${params.data?.eventSource}`);
        },
      },
      {
        colId: ColumnID.GPS_ACCURACY,
        headerName: t('event.coordinates_accuracy'),
        field: EventModelFieldNames.GpsAccuracyLevel,
        cellRenderer: GpsAccuracyLevelCellRenderer,
        valueFormatter: (params: ValueFormatterParams<EventModel>) => {
          if (params.data?.gpsAccuracyLevel) return formatGpsAccuracy(params.data?.gpsAccuracyLevel);
          else {
            return t('unknown');
          }
        },
      },
      {
        colId: ColumnID.GPS_SOURCE_TYPE,
        headerName: t('event.coordinates_source'),
        field: EventModelFieldNames.GpsSourceType,
        valueGetter: (params: ValueGetterParams<EventModel>) => {
          return t(`event.gps_source.${params.data?.gpsSourceType}`);
        },
      },
    ];

    // Apply saved column state to the column definitions
    if (events) {
      applyStateToDefinitions(cols);
    }

    return cols;
  }, [events, columnState]);

  const handleSearch = (filterValues: FilterValues, page?: number) => {
    console.log(JSON.stringify(filterValues));
    if (!validateFilterValues(filterValues)) {
      return;
    }

    setFiltersToUrl({
      ...filterValues,
      pageNumber: page ?? filters.current.pageNumber,
    });

    setApiCallArg(() =>
      eventService.get(
        {
          dateFrom: filters.current.dateFrom?.toISOString(),
          dateTo: filters.current.dateTo?.toISOString(),
          locationId: filters.current.locationId,
          assetId: filters.current.assetId,
          eventType: filters.current.eventType,
        },
        {
          page: filters.current.pageNumber,
          limit: EVENT_FETCH_LIMIT,
          order: filters.current.sortDirection,
          sort: filters.current.sortOption,
        },
      ),
    );

    setFiltersToUrl(filterValues);
  };

  function onFilterChange(filterValues: FilterValues) {
    console.log(JSON.stringify(filterValues));
  }

  function validateFilterValues(filterValues: FilterValues): boolean {
    if (filterValues.dateFrom && filterValues.dateTo) {
      // Date from cannot be after date to
      if (filterValues.dateFrom.isAfter(filterValues.dateTo)) {
        toast.warning(t('event.errors.date_from_after'));
        return false;
      } else if (filterValues.dateTo.isBefore(filterValues.dateFrom)) {
        toast.warning(t('event.errors.date_to_before'));
        return false;
      }
    }
    return true;
  }

  const onPageChanged = (event: React.ChangeEvent<unknown>, page: number) => {
    handleSearch(
      {
        ...filters.current,
        pageNumber: page,
      },
      page,
    );
  };

  return (
    <TabbedPageLayout>
      <TabbedPageLayoutBody>
        <div className="flex h-full flex-grow flex-col  ">
          {isError ? (
            <ErrorLoadingDataAlert />
          ) : (
            <>
              <div className="mb-2 flex items-center justify-between">
                <div className="flex items-center">
                  <FilterBar onSearch={handleSearch} onChange={onFilterChange} initialFilterValues={filters.current} showAsPopover>
                    <div className="flex flex-col gap-y-2">
                      <div className="w-full">
                        <LocationFilter label="Location" />
                      </div>
                      <div className="w-full">
                        <AssetFilter label="Asset" />
                      </div>
                      <div className="flex gap-x-2">
                        <div className="w-48">
                          {/* .utc(true) takes the date as is without converting from local time first */}
                          <DateFilter
                            label="Date From"
                            filterField={FilterFieldName.dateFrom}
                            processValue={(date) => date.startOf('day').utc(true)}
                          />
                        </div>
                        <div className="w-48">
                          <DateFilter
                            label="Date To"
                            filterField={FilterFieldName.dateTo}
                            processValue={(date) => date.endOf('day').utc(true)}
                          />
                        </div>
                      </div>
                      <ListSelectFilter
                        label="Event Type"
                        filterFieldName={FilterFieldName.eventType}
                        options={[
                          EventType.Incoming,
                          EventType.Inventory,
                          EventType.Observation,
                          EventType.Outgoing,
                          EventType.ADMINISTRATIVE,
                          null,
                        ]}
                        renderOption={(item) => {
                          switch (item) {
                            case null:
                              return <div className="italic">{t('event.type.all')}</div>;
                            default:
                              return t(`event.type.${item}`);
                          }
                        }}
                        valueExtractor={(item) => item}
                      />
                    </div>
                    <FilterBarSearchButton isLoading={isLoading} />
                  </FilterBar>
                </div>
              </div>
              <PoGrid
                isLoading={isLoading}
                colDefs={columns}
                rowData={events?.data}
                gridOptions={customGridOptions}
                disableDefaultGridOptions
              />
              <div className="flex items-center gap-x-4">
                <PaginationControls
                  isLoading={isLoading}
                  totalPageCount={events?.totalPages ?? 1}
                  currentPage={filters.current.pageNumber ?? 1}
                  totalElements={events?.totalElements ?? 0}
                  onChange={onPageChanged}
                />
                <PagedResultDataText data={events} name={t('event.event_other')} />
              </div>
            </>
          )}
        </div>
        {selectedEventId !== undefined && (
          <EventInfoDrawer eventId={selectedEventId} isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} />
        )}
      </TabbedPageLayoutBody>
    </TabbedPageLayout>
  );
};
