import { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { AssetCreateFormSchema, AssetFormModel, AssetFormSchema } from './AssetFormModel';
import { zodResolver } from '@hookform/resolvers/zod';
import AssetFormDisplay from './AssetFormDisplay';
import { assetService } from '../../api/assets/asset.service';
import { useConfirmationModal } from '@/components/modals/confirmation-modal/useConfirmationModal';
import { useNavigate } from 'react-router-dom';

interface AssetFormLogicProps {
  initialValues: AssetFormModel;
  onSubmit: (data: AssetFormModel) => Promise<boolean>;
  isCreate?: boolean;
  assetId?: number;
}

const AssetFormLogic: FunctionComponent<AssetFormLogicProps> = ({ initialValues, onSubmit, isCreate, assetId }) => {
  const navigate = useNavigate();
  const form = useForm<AssetFormModel>({
    mode: 'onSubmit',
    defaultValues: initialValues,
    resolver: zodResolver(isCreate ? AssetCreateFormSchema : AssetFormSchema),
  });

  const { ModalComponent, handleOpenModal, setIsLoading, setIsModalOpen } = useConfirmationModal({
    onConfirm: onUnarchiveConfirmed,
    questionTitle: 'Unarchive Asset',
    questionText: 'The asset code you are trying to edit is already used in an archived asset, do you want to unarchive the asset instead?',
    confirmButtonText: 'Unarchive',
    cancelButtonText: 'Cancel',
    closeOnConfirm: true,
  });

  async function onUnarchiveConfirmed(id?: number) {
    if (id) {
      setIsLoading(true);
      const response = await assetService.unarchive(id);
      if (response.isSuccess) {
        navigate(`/app/assets/${id}`);
        setIsModalOpen(false);
      } else {
        setIsLoading(false);
      }
    }
  }

  const handleSubmit = async (data: AssetFormModel) => {
    // Check if the code is unique
    if (isCreate || data.code != initialValues.code) {
      const existingAssetResponse = await assetService.getByCode(data.code);

      if (existingAssetResponse.isSuccess && existingAssetResponse.payload) {
        if (existingAssetResponse.payload.deletedAt) {
          handleOpenModal(existingAssetResponse.payload.id);
          return;
        } else {
          form.setError('code', {
            type: 'manual',
            message: 'An asset with this code already exists',
          });
          return;
        }
      }
    }

    await onSubmit(data)
      .then(() => form.reset(data))
      .catch((err) => console.error(err));
  };

  return (
    <>
      <AssetFormDisplay form={form} onSubmit={handleSubmit} isCreate={isCreate} assetId={assetId} />
      {ModalComponent}
    </>
  );
};

export default AssetFormLogic;
