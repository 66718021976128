import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import {
  ColDef,
  GridApi,
  GridOptions,
  ICellRendererParams,
  RowDoubleClickedEvent,
  SelectionChangedEvent,
  GridReadyEvent,
  SortChangedEvent,
} from 'ag-grid-community';
import { LocationModel } from '@/modules/locations/types/LocationModel';
import LabelCellRenderer, { LabelCellRendererParams } from '@/components/grid/cells/LabelCellRenderer';
import { BASE_GRID_OPTIONS, PoGrid } from '@/components/grid/PoGrid';
import { useGridColumnState } from '@/hooks/useGridColumnState';
import { UserSettingKey } from '@/modules/users/api/user-settings/user-setting.contracts';
import { ColumnID } from '@/components/grid/column-ids';
import { PageSortOption, PageSortOrder } from '@/lib/api/pagination.page.dto';
import { LocationSortOption } from '@/modules/locations/api/locations/location.contracts';

interface IndividualLocationsGridProps {
  data: LocationModel[] | undefined;
  isLoading: boolean;
  onSelectionChanged: (selectedRows: LocationModel[]) => void;
  onRowDoubleClicked: (locationId: string) => void;
  hasPermission: boolean;
  initialSortOption?: PageSortOption;
  initialSortOrder?: PageSortOrder;
  onSortChanged: (sortOrder: SortChangedEvent<LocationModel>) => void;
}

const IndividualLocationsGrid: FunctionComponent<IndividualLocationsGridProps> = ({
  data,
  isLoading,
  onSelectionChanged,
  onRowDoubleClicked,
  hasPermission,
  initialSortOption,
  initialSortOrder,
  onSortChanged,
}) => {
  const [gridApi, setGridApi] = useState<GridApi<LocationModel>>();

  // Initialize the grid column state hook
  const {
    setColumnStateGridApi,
    handleColumnStateChange,
    columnState,
    applyStateToDefinitions,
    setIsAutoSaveEnabled,
    setDefaultColumnState,
  } = useGridColumnState(UserSettingKey.LOCATION_INDIVIDUAL_GRID_COLUMN_STATE);

  // Define the column definitions
  const columnDefs = useMemo<ColDef<LocationModel>[]>(() => {
    const checkboxColumn: ColDef<LocationModel> = {
      colId: ColumnID.SELECTION_CHECKBOX,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      resizable: false,
      width: 40,
      minWidth: 40,
      maxWidth: 40,
      suppressColumnsToolPanel: true,
      suppressMenu: true,
      lockVisible: true,
      sortable: false,
    };

    const columns: ColDef<LocationModel>[] = [
      {
        colId: ColumnID.LOCATION_NAME,
        field: 'dto.name',
        headerName: 'Name',
        sortable: true, // Enable sorting
      },
      {
        colId: ColumnID.LOCATION_CODE,
        field: 'dto.code',
        headerName: 'Code',
        width: 150,
        sortable: true, // Enable sorting
      },
      {
        colId: ColumnID.LOCATION_LABELS,
        field: 'dto.labels',
        headerName: 'Labels',
        cellRenderer: LabelCellRenderer,
        cellRendererParams: (params: ICellRendererParams<LocationModel>): LabelCellRendererParams => {
          const labels = params.data?.dto.labels ?? [];
          return {
            labels,
          };
        },
        valueFormatter: (params) => {
          return params.data?.dto.labels?.map((label) => label.value.value).join(', ') ?? '-';
        },
        flex: 1,
        sortable: true, // Enable sorting
      },
    ];

    if (hasPermission) {
      columns.unshift(checkboxColumn);
    }

    // Set default column state if not already set
    if (!columnState) {
      setDefaultColumnState([
        { colId: ColumnID.SELECTION_CHECKBOX, hide: !hasPermission },
        { colId: ColumnID.LOCATION_NAME, hide: false },
        { colId: ColumnID.LOCATION_CODE, hide: false },
        { colId: ColumnID.LOCATION_LABELS, hide: false },
      ]);
    }

    // Apply saved column state to the column definitions
    applyStateToDefinitions(columns);

    return columns;
  }, [hasPermission, columnState]);

  // Enable auto-saving of column state
  useEffect(() => {
    setIsAutoSaveEnabled(true);
  }, []);

  // Handle sorting changes
  const handleSortChange = (event: SortChangedEvent<LocationModel>) => {
    handleColumnStateChange(event); // Handle the state change for columns

    // Call the onSortChanged callback to update the external state
    onSortChanged(event);

    // Apply the sort state to the grid
    const column = event.columns && event.columns[0];
    if (column) {
      gridApi?.applyColumnState({
        state: [
          {
            colId: column.getColId(),
            sort: column.getSort(),
          },
        ],
        defaultState: { sort: null },
      });
    }
  };

  // Define the grid options
  const gridOptions: GridOptions<LocationModel> = {
    ...BASE_GRID_OPTIONS,
    onRowDoubleClicked(event: RowDoubleClickedEvent<LocationModel>) {
      const id = event.data?.dto.id.toString() ?? '';
      onRowDoubleClicked(id);
    },
    getRowId: (params) => params.data.dto.id.toString(),
    rowSelection: 'multiple',
    onSelectionChanged: (event: SelectionChangedEvent<LocationModel>) => {
      onSelectionChanged(event.api.getSelectedRows());
    },
    onGridReady: (params: GridReadyEvent<LocationModel>) => {
      setGridApi(params.api);
      setColumnStateGridApi(params.api);

      // Apply initial sorting if provided
      if (initialSortOption && initialSortOrder) {
        let colId: ColumnID | undefined;

        switch (initialSortOption) {
          case LocationSortOption.NAME:
            colId = ColumnID.LOCATION_NAME;
            break;
          case LocationSortOption.CODE:
            colId = ColumnID.LOCATION_CODE;
            break;
          default:
            break;
        }

        if (colId) {
          params.api.applyColumnState({
            state: [
              {
                colId,
                sort: initialSortOrder === PageSortOrder.ASC ? 'asc' : 'desc',
              },
            ],
            defaultState: { sort: null },
          });
        }
      }
    },
    // Grid column state change handlers
    onColumnMoved: handleColumnStateChange,
    onColumnVisible: handleColumnStateChange,
    onColumnResized: handleColumnStateChange,
    onColumnPinned: handleColumnStateChange,
    onSortChanged: handleSortChange, // Add the sorting handler
  };

  return (
    <PoGrid
      isLoading={isLoading}
      colDefs={columnDefs}
      rowData={data}
      gridOptions={gridOptions}
      disableResizeColumnsToFit
      disableDefaultGridOptions
    />
  );
};

export default IndividualLocationsGrid;
