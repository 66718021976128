import { useApiCall } from '@/hooks/useApiCall';
import { FunctionComponent, useState } from 'react';
import { eventService } from '@/modules/events/api/events/event.service';
import { EventModel } from '@/modules/events/types/EventModel';
import { AssetEventsGrid } from './AssetEventsGrid';
import LoadingBlock from '@/components/feedback/LoadingBlock';
import { PagePaginationResultDto, PageSortOrder } from '@/lib/api/pagination.page.dto';
import { EVENT_FETCH_LIMIT } from '@/modules/events/pages/EventsPage';
import { EventSortOption } from '@/modules/events/api/events/event.contracts';
import PaginationControls from '@/components/grid/PaginationControls';
import PagedResultDataText from '@/components/filterbar/PagedResultDataText';
import { useTranslation } from '@/lib';

interface AssetEventsTabProps {
  assetId: number;
}

export const AssetEventsTab: FunctionComponent<AssetEventsTabProps> = ({ assetId }) => {
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(1);
  const {
    data: events,
    isLoading,
    isError,
    setApiCallArg,
  } = useApiCall<PagePaginationResultDto<EventModel>>(() =>
    eventService.get(
      { assetId },
      {
        page: page,
        limit: EVENT_FETCH_LIMIT,
        order: PageSortOrder.DESC,
        sort: EventSortOption.OCCURRED_AT,
      },
    ),
  );

  const handlePage = (page?: number) => {
    setApiCallArg(() =>
      eventService.get(
        {
          assetId: assetId,
        },
        {
          page: page ?? 1,
          limit: EVENT_FETCH_LIMIT,
          order: PageSortOrder.DESC,
          sort: EventSortOption.OCCURRED_AT,
        },
      ),
    );
  };

  const onPageChanged = (event: React.ChangeEvent<unknown>, page: number) => {
    handlePage(page);
    setPage(page);
  };

  return (
    <div className="flex h-full flex-1 flex-grow flex-col ">
      {isLoading ? (
        <LoadingBlock />
      ) : (
        <>
          <AssetEventsGrid data={events?.data} isError={isError}></AssetEventsGrid>
          <div className="flex items-center gap-x-4">
            <PaginationControls
              isLoading={isLoading}
              totalPageCount={events?.totalPages ?? 1}
              currentPage={page}
              totalElements={events?.totalElements ?? 0}
              onChange={onPageChanged}
            />
            <PagedResultDataText data={events} name={t('event.event', { count: events?.totalElements })} />
          </div>
        </>
      )}
    </div>
  );
};
