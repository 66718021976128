export enum ColumnID {
  LOCATION_NAME = 'LOCATION_NAME',
  LOCATION_CODE = 'LOCATION_CODE',
  CURRENT_BALANCE = 'CURRENT_BALANCE',
  TOTAL_INCOMING = 'TOTAL_INCOMING',
  TOTAL_OUTGOING = 'TOTAL_OUTGOING',
  RETURN_RATE = 'RETURN_RATE',
  STAYTIME = 'STAYTIME',
  STAYTIME_60_DAYS = 'STAYTIME_60_DAYS',
  TOTAL_BALANCE = 'TOTAL_BALANCE',
  SELECTION_CHECKBOX = 'SELECTION_CHECKBOX',
  TRACKER_TYPE = 'TRACKER_TYPE',
  TRACKER_NAME = 'TRACKER_NAME',
  LAST_EVENT_DATE = 'LAST_EVENT_DATE',
  FIRST_EVENT_DATE = 'FIRST_EVENT_DATE',
  ASSET_CODE = 'ASSET_CODE',
  ASSET_TYPE_NAME = 'ASSET_TYPE_NAME',
  OCCURRED_AT = 'OCCURRED_AT',
  EVENT_TYPE = 'EVENT_TYPE',
  GPS_ACCURACY = 'GPS_ACCURACY',
  GPS_SOURCE_TYPE = 'GPS_SOURCE_TYPE',
  EVENT_SOURCE = 'EVENT_SOURCE',
  DATE_ARRIVED_AT_LOCATION = 'DATE_ARRIVED_AT_LOCATION',
  LOCATION_LABELS = 'LOCATION_LABELS',
  LOCATION_SUBLOCATIONS = 'LOCATION_SUBLOCATIONS',
}
