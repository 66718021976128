import { FunctionComponent, SyntheticEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import AssetFormApi from '../components/asset-form/AssetFormApi';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Skeleton, Tab } from '@mui/material';
import { AssetEventsTab } from '../components/asset-event-tab/AssetEventsTab';
import { AssetHistoryTab } from '../components/asset-history-tab/AssetHistoryTab';
import { BackButton } from '@/components/BackButton';
import { TabbedLayoutTopBar } from '@/modules/application/components/TabbedLayoutTopBar';
import TabbedLayoutTopBarHeader from '@/modules/application/components/TabbedLayoutTopBarHeader';
import { TabbedLayoutTopBarVerticalSeperator } from '@/modules/application/components/TabbedLayoutTopBarVerticalSeperator';
import { TabbedPageLayoutBody } from '@/modules/application/components/TabbedPageLayoutBody';
import { TabbedLayout } from '@/modules/application/layouts/TabbedLayout';
import { AssetModel } from '../types/AssetModel';
import { assetService } from '../api/assets/asset.service';
import BetaLabel from '@/components/BetaLabel';

interface AssetDetailPageProps {
  isCreate?: boolean;
}

export const AssetDetailPage: FunctionComponent<AssetDetailPageProps> = ({ isCreate }) => {
  const { assetId } = useParams();

  const [value, setValue] = useState('1');
  const [asset, setAsset] = useState<AssetModel | undefined>(undefined);

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (assetId) {
      assetService.getById(Number.parseInt(assetId)).then((asset) => {
        if (asset.isSuccess) {
          setAsset(asset.payload);
        }
      });
    }
  }, [assetId]);

  return (
    <TabContext value={value}>
      <TabbedLayout
        topBar={
          <TabbedLayoutTopBar>
            <BackButton pathname="../" />
            <TabbedLayoutTopBarHeader icon={null}>
              {isCreate ? 'New Asset' : asset?.code ? `Asset - ${asset.code}` : <Skeleton width={200} />}
            </TabbedLayoutTopBarHeader>
            <TabbedLayoutTopBarVerticalSeperator />
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Details" value="1" />
              <Tab label="Events" value="2" />
              {assetId && (
                <Tab
                  label={
                    <div className="flex items-center">
                      History
                      <div className="ml-2">
                        <BetaLabel />
                      </div>
                    </div>
                  }
                  value="3"
                />
              )}
            </TabList>
          </TabbedLayoutTopBar>
        }
      >
        {/* <PageLayoutTopBar header={isCreate ? 'New Location' : `Location`} navigateBackPathname={'../'} /> */}
        <TabbedPageLayoutBody>
          <div className="flex h-full flex-grow flex-col ">
            <TabPanel className="p-0" value="1" style={{ flexGrow: 1, height: '100%' }}>
              <AssetFormApi isCreate={isCreate} assetId={assetId ? Number.parseInt(assetId) : undefined} />
            </TabPanel>
            <TabPanel className="p-0" value="2" style={{ flexGrow: 1, position: 'relative' }}>
              {assetId && <AssetEventsTab assetId={Number.parseInt(assetId)}></AssetEventsTab>}
            </TabPanel>
            {assetId && (
              <TabPanel className="p-0" value="3" style={{ flexGrow: 1, height: '100%' }}>
                <AssetHistoryTab assetId={Number.parseInt(assetId)}></AssetHistoryTab>
              </TabPanel>
            )}
          </div>
        </TabbedPageLayoutBody>
      </TabbedLayout>
    </TabContext>
  );
};
