import LoadingBlock from '@/components/feedback/LoadingBlock';
import { useApiCall } from '@/hooks/useApiCall';
import { FunctionComponent, useEffect } from 'react';
import { toast } from 'react-toastify';
import AssetFormLogic from './AssetFormLogic';
import { AssetFormModel } from './AssetFormModel';
import { useNavigate } from 'react-router-dom';
import { isNumber } from 'lodash-es';
import { AssetModel } from '../../types/AssetModel';
import { assetService } from '../../api/assets/asset.service';
import { ApiResponseCode } from '@/lib/api/api-response-code';
import { useUser } from '@/modules/users/contexts/UserContext';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';

interface AssetFormApiProps {
  isCreate?: boolean;
  assetId?: number;
}

const AssetFormApi: FunctionComponent<AssetFormApiProps> = ({ isCreate, assetId }) => {
  const navigate = useNavigate();
  const hasAssetsEditPermission = useUser().hasPermission(Permission.ASSETS_EDIT);
  const { data, isLoading, isError, setApiCallArg } = useApiCall<AssetModel>(() => assetService.getById(0), {
    manual: true,
  });

  useEffect(() => {
    if (assetId) {
      setApiCallArg(() => assetService.getById(assetId));
    }
  }, [assetId]);

  const handleSubmit = async (data: AssetFormModel) => {
    if (!hasAssetsEditPermission) {
      toast.error('You do not have permission to edit assets');
      return false;
    }

    // Process data
    if (isCreate) {
      const response = await assetService.create({
        code: data.code,
        assetTypeId: data.assetTypeId,
      });

      if (!response.isSuccess) {
        if (response.responseCode === ApiResponseCode.DUPLICATE_ENTITY_CODE) {
          toast('The code is already in use, and must be unique. Please provide a different one.');
        } else {
          toast('Error creating Asset', {
            type: 'error',
          });
        }
      } else {
        const trackerUpdateResponse = await assetService.updateTrackers(response.payload.id, data.trackers ?? []);
        if (trackerUpdateResponse.isSuccess) {
          toast.success('Asset created');
        } else {
          toast.warning(
            'The Asset was created, but something went wrong when linking the Trackers. Please check the Asset to see if the correct Trackers are linked.',
            { autoClose: 10000 },
          );
        }
        navigate('/app/assets/overview');
      }
    } else if (isNumber(assetId)) {
      const response = await assetService.update(assetId, {
        code: data.code,
        assetTypeId: data.assetTypeId,
      });

      const trackerUpdateResponse = await assetService.updateTrackers(assetId, data.trackers ?? []);

      if (!response.isSuccess || !trackerUpdateResponse.isSuccess) {
        if (response.responseCode === ApiResponseCode.DUPLICATE_ENTITY_CODE) {
          toast('The code is already in use, and must be unique. Please provide a different one.');
        } else {
          toast('Error Updating Asset', {
            type: 'error',
          });
        }
      } else {
        toast.success('Asset updated');
        navigate('/app/assets/overview/');
      }
    }
    return true;
  };

  if (isCreate || !assetId) {
    const newAsset: AssetFormModel = {
      code: '',
      assetTypeId: 0,
      trackers: [],
    };
    return <AssetFormLogic initialValues={newAsset} onSubmit={handleSubmit} isCreate assetId={assetId} />;
  } else {
    // return early if initial form data isn't loaded
    if (isLoading) return <LoadingBlock />;
    if (isError || !data) return <div>No Data Error</div>;

    const existingAsset: AssetFormModel = {
      code: data.code,
      assetTypeId: data.assetTypeId,
      trackers: data.trackers.map((t) => t.id),
    };

    return <AssetFormLogic initialValues={existingAsset} onSubmit={handleSubmit} isCreate={isCreate} assetId={assetId} />;
  }
};

export default AssetFormApi;
